.MuiTooltip-tooltip {
  color: inherit !important;
  font-size: 0.75rem !important;
  padding: 0.5rem 0.75rem !important;
  max-width: 300px;
  word-wrap: break-word;
  font-family: inherit !important;
  font-weight: bold;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: #79a9f5 !important;
}

.MuiTooltip-arrow {
  color: #79a9f5 !important;
}
