.data-range {
  width: 100%;
  margin-top: 0.875rem;

  &:first-of-type {
    margin-top: 0;
  }

  @media (min-width: 360px) {
    width: calc(50% - 0.5rem);
    margin-top: 0;

    &:first-of-type {
      margin-right: 1rem;
    }
  }
}
