.conditional-fields {
  display: flex;
  margin-top: 0.5rem;

  &:not(:first-of-type) {
    margin-top: 1.5rem;
    padding-top: 0.5rem;

    @media (max-width: 800px) {
      border-top: 1px solid;
    }
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }

  &__field {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    min-height: 40px;
    padding-top: 0.875rem;

    @media (min-width: 360px) {
      flex: 1;

      &:nth-child(1) {
        width: auto;
        flex: unset;
        min-width: 100px;
        margin-right: 1rem;
      }

      &:last-of-type {
        min-width: 100%;
      }
    }

    @media (min-width: 500px) {
      min-width: 300px;
    }

    @media (min-width: 800px) {
      padding-top: 0;

      &:not(:first-of-type) {
        flex: 1;
      }

      &:not(:last-of-type) {
        margin-right: 1rem;
        padding-top: 0;
      }

      &:last-of-type {
        min-width: unset;
      }
    }
  }
}
