.link-card {
  padding: 0.75rem;
  margin-bottom: 1rem;

  &:hover {
    a {
      text-decoration: underline;
    }
  }

  svg {
    flex-shrink: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    letter-spacing: 0.075em !important;
    // font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  &__arrow {
    margin-right: 0.75rem;
  }

  &__title {
    display: block;
    margin-bottom: 0.5rem !important;
  }
}
